<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Cập nhật thông tin cửa hàng'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <b-row>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Tên:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-1"
                      size="sm"
                      v-model="$v.form.name.$model"
                      :state="validateState('name')"
                      required
                      placeholder="Nhập tên"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Doanh nghiệp:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      class="mt-2 select-style"
                      size="sm"
                      id="input-4"
                      v-model="$v.form.selectedCompany.$model"
                      :options="listCompany"
                      :state="validateState('selectedCompany')"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Doanh nghiệp --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng chọn doanh nghiệp</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group id="input-group-1" class="p-0">
                    <label>Kinh độ:</label>
                    <b-input class="mt-2" size="sm" v-model="form.longitude">
                    </b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Tên viết tắt:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-1"
                      size="sm"
                      v-model="$v.form.shortName.$model"
                      :state="validateState('shortName')"
                      required
                      placeholder="Nhập tên viết tắt"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Khu vực:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      class="mt-2 select-style"
                      size="sm"
                      id="input-4"
                      v-model="$v.form.selectedArea.$model"
                      :options="listArea"
                      :state="validateState('selectedArea')"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Khu vực --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng chọn khu vực</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group id="input-group-1" class="p-0">
                    <label>Vĩ độ:</label>
                    <b-input
                      class="mt-2"
                      size="sm"
                      v-model="form.latitude"
                    ></b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group>
                    <template>
                      <label>Tên hệ thống ngoài:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      size="sm"
                      v-model="$v.form.externalStoreName.$model"
                      :state="validateState('externalStoreName')"
                      required
                      placeholder="Nhập tên hệ thống ngoài"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <template>
                      <label>Loại cửa hàng:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      class="mt-2 select-style"
                      size="sm"
                      v-model="$v.form.seletedStoreType.$model"
                      :options="[
                        { id: 1, name: 'Showroom DDV' },
                        { id: 2, name: 'Showroom VDD' },
                        { id: 3, name: 'Mobifone' },
                        { id: 4, name: 'Kho' },
                      ]"
                      :state="validateState('seletedStoreType')"
                      value-field="id"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Loại cửa hàng --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback
                      >Vui lòng chọn loại cửa hàng</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Trạng thái:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      class="mt-2 select-style"
                      size="sm"
                      id="input-4"
                      v-model="form.isActive"
                      :options="listStoreActive"
                      :state="validateState('isActive')"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Trạng thái --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group>
                    <template>
                      <label>Số điện thoại:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      size="sm"
                      v-model="$v.form.phoneNumber.$model"
                      :state="validateState('phoneNumber')"
                      required
                      placeholder="Nhập số điện thoại"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.form.phoneNumber.required"
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback v-if="!$v.form.phoneNumber.numeric"
                      >Số điện thoại không hợp lệ</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Thành phố:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      class="mt-2 select-style"
                      size="sm"
                      id="input-4"
                      v-model="$v.form.selected.$model"
                      :options="listCity"
                      :state="validateState('selected')"
                      value-field="id"
                      text-field="name"
                      v-on:change="changeCity"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Thành phố --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng chọn thành phố</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <div class="d-inline-flex">
                    <b-checkbox
                      v-model="form.showOnLoyalty"
                      class="ml-2"
                    ></b-checkbox>
                    <label class="font-weight-bold">Hiển thị ở app D-MEM</label>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Địa chỉ:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      size="sm"
                      v-model="$v.form.address.$model"
                      :state="validateState('address')"
                      required
                      placeholder="Nhập địa chỉ"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Quận:</label>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      size="sm"
                      class="mt-2 select-style"
                      id="input-3"
                      :state="validateState('selectedDistrict')"
                      v-model="$v.form.selectedDistrict.$model"
                      :options="listDistrict"
                      required
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Quận --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Vui lòng chọn quận</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Shipcode:</label>
                    </template>
                    <b-form-input
                      id="input-2"
                      size="sm"
                      v-model="form.shipCode"
                      placeholder="Nhập Shipcode"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="input-group-1" style="padding: 0px">
                    <template>
                      <label>Sitecode:</label>
                    </template>
                    <b-form-input
                      id="input-2"
                      size="sm"
                      v-model="form.siteCode"
                      placeholder="Nhập Sitecode"
                      class="mt-2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback"
                      >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="updateStore"
              v-if="checkPermission('STORE_UPDATE')"
              >Lưu</b-button
            >
            <router-link :to="'/stores'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { required, numeric } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { getToastInstance } from '@/utils/toastHelper';
import { IS_ACTIVE } from '@/utils/enum';
import { IS_ACTIVE_NAME } from '@/utils/enum-name';

export default {
  mixins: [validationMixin],
  data() {
    return {
      storeID: null,
      valid: true,
      nameRules: [
        (v) => !!v || 'Vui lòng nhập tên!',
        (v) => (v && v.length > 1) || 'Tên không hợp lệ!',
      ],
      addressRules: [
        (v) => !!v || 'Vui lòng nhập địa chỉ!',
        (v) => (v && v.length > 1) || 'Địa chỉ không hợp lệ!',
      ],
      listCity: [],
      listDistrict: [],
      isNew: true,
      form: {
        name: '',
        shortName: '',
        externalStoreName: '',
        phoneNumber: '',
        address: '',
        selected: null,
        selectedDistrict: null,
        selectedCompany: null,
        selectedArea: null,
        seletedStoreType: null,
        siteCode: null,
        shipCode: null,
        longitude: null,
        latitude: null,
        isActive: null,
        showOnLoyalty: 1,
      },
      listCompany: [],
      listStoreActive: [
        {
          id: null,
          name: 'Trạng thái',
        },
        {
          id: IS_ACTIVE.TRUE,
          name: IS_ACTIVE_NAME[IS_ACTIVE.TRUE],
        },
        {
          id: IS_ACTIVE.FALSE,
          name: IS_ACTIVE_NAME[IS_ACTIVE.FALSE],
        },
      ],
      listArea: [],
    };
  },
  validations: {
    form: {
      name: { required },
      shortName: { required },
      externalStoreName: { required },
      phoneNumber: { required, numeric },
      address: { required },
      selected: { required },
      selectedDistrict: { required },
      selectedCompany: { required },
      selectedArea: { required },
      seletedStoreType: { required },
      isActive: { required },
    },
  },
  components: {
    KTCodePreview,
  },
  created() {
    this.getStoreByID();
    this.fetchCity();
    this.fetchCompany();
    this.fetchArea();
  },
  mounted() {
    if (this.checkPermission('STORE_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Cửa hàng', route: '/stores' },
        { title: 'Danh sách cửa hàng', route: '/stores' },
        { title: 'Cập nhật cửa hàng' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Cửa hàng', route: '/stores' },
        { title: 'Danh sách cửa hàng', route: '/stores' },
        { title: 'Thông tin cửa hàng' },
      ]);
    }
  },
  methods: {
    ...getToastInstance(),
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    updateStore() {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let data = {
        data: {
          id: this.storeID,
          name: this.$v.form.name.$model,
          shortName: this.$v.form.shortName.$model,
          externalStoreName: this.$v.form.externalStoreName.$model,
          phoneNumber: this.$v.form.phoneNumber.$model,
          companyId: this.$v.form.selectedCompany.$model,
          areaId: this.$v.form.selectedArea.$model,
          address: this.$v.form.address.$model,
          district: this.$v.form.selectedDistrict.$model,
          city: this.$v.form.selected.$model,
          storeType: this.$v.form.seletedStoreType.$model,
          shipCode: this.form.shipCode,
          siteCode: this.form.siteCode,
          isActive: this.form.isActive,
          deleted: false,
          longitude: this.form.longitude,
          latitude: this.form.latitude,
          showOnLoyalty: this.form.showOnLoyalty,
        },
      };
      ApiService.setHeader();
      ApiService.put(`stores`, data)
        .then(() => {
          this.makeToastSuccess('Cập nhật thành công');
          this.$router.push({
            path: '/stores',
          });
        })
        .catch(() => {
          this.makeToastFailure('Không thể cập nhật cửa hàng');
        });
    },
    getStoreByID: function() {
      this.storeID = this.$route.query.id;
      ApiService.setHeader();
      ApiService.get(`stores/${this.storeID}`).then((data) => {
        const store = data.data.data;
        this.form.name = store.name;
        this.form.shortName = store.shortName;
        this.form.externalStoreName = store.externalStoreName;
        this.form.phoneNumber = store.phoneNumber;
        this.form.address = store.address;
        this.form.selected = store.city;
        this.form.selectedDistrict = store.district;
        this.form.selectedCompany = store.companyId;
        this.form.selectedArea = store.areaId;
        this.form.seletedStoreType = store.storeType;
        this.form.shipCode = store.shipCode;
        this.form.siteCode = store.siteCode;
        this.form.longitude = store.longitude;
        this.form.latitude = store.latitude;
        this.form.isActive = Number(store.isActive);
        this.form.showOnLoyalty = store.showOnLoyalty;
        if (store.city != null) {
          this.getDistrict();
        }
      });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    fetchCity: function() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict: function() {
      ApiService.setHeader();
      ApiService.get(`city/${this.$v.form.selected.$model}/district`).then(
        (data) => {
          this.listDistrict = data.data.data;
        },
      );
    },
    changeCity() {
      this.$v.form.selectedDistrict.$model = null;
      this.getDistrict();
    },
    fetchCompany: async function() {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
      });
    },
    fetchArea: async function() {
      this.listArea = [];
      ApiService.setHeader();
      ApiService.get('/area').then((response) => {
        this.listArea = response.data.data;
      });
    },
  },
};
</script>
